import React from "react"
import * as styles from "./informationCtas.module.scss"

function informationCtas({ data, columns }) {

  const callToActions = data.map(cta => {
    return (
      <div className={styles.infoCtas__item}>
        <div className={styles.infoCtas__item__top} dangerouslySetInnerHTML={{ __html: cta.textTop.childMarkdownRemark.html }} />

        <div className={styles.infoCtas__item__bottom} dangerouslySetInnerHTML={{ __html: cta.textBottom.childMarkdownRemark.html }} />
      </div>
    )
  });

  return (
    <div className={styles.infoCtas} data-columns={columns}>
      {callToActions}
    </div>
  )
}

export default informationCtas