import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import FullWidthText from "../../components/Global/fullWidthText/fullWidthText"
import InfoCtas from "../../components/Global/informationCtas/informationCtas"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"

const SafetyPage = ({ data }) => {

  const content = data.allContentfulSafetyProfile.nodes[0];

  let heroData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
  }

  let section1IntroData = content.section1Intro
  let section1CtasData = content.section1Ctas
  let section1FootnoteData = content.section1Footnote

  let section2IntroData = content.section2Intro
  let section2CtasData = content.section2Ctas
  let section2OutroData = content.section2Outro

  let graphTitleData = content.graphTitle
  let graphImageData = getImage(content.graph)
  let graphOutroData = content.graphOutro

  let fullWidthBanner1Data = {
    imageTitle: content.fullWidthBanner1.image.title,
    image: getImage(content.fullWidthBanner1.image),
  }

  let fullWidthBanner2Data = {
    imageTitle: content.fullWidthBanner2.image.title,
    image: getImage(content.fullWidthBanner2.image),
  }

  let fullWidthCtaData = {
    title: content.relatedPages.title,
    buttons: content.relatedPages.buttons
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: content.footerData,
  }

  return (
    <Layout>
      <Seo title="Safety profile" />
      <Hero data={heroData}/>
      <FullWidthText data={section1IntroData} />
      <InfoCtas data={section1CtasData} columns="2" />
      <FullWidthText data={section1FootnoteData} />
      <FullWidthText data={section2IntroData} />
      <InfoCtas data={section2CtasData} columns="4"/>
      <FullWidthText data={section2OutroData} />
      <FullWidthImage data={fullWidthBanner1Data} />
      <FullWidthText data={graphTitleData} />
      <GatsbyImage objectFit="contain" className="graph__column__2-9" image={graphImageData} />
      <FullWidthText data={graphOutroData} />
      <FullWidthCTA data={fullWidthCtaData} />
      <FullWidthImage data={fullWidthBanner2Data} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulSafetyProfile {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        section1Intro {
          childMarkdownRemark {
            html
          }
        }
        section1Ctas {
          textTop {
            childMarkdownRemark {
              html
            }
          }
          textBottom {
            childMarkdownRemark {
              html
            }
          }
        }
        section1Footnote {
          childMarkdownRemark {
            html
          }
        }
        section2Intro {
          childMarkdownRemark {
            html
          }
        }
        section2Ctas {
          textTop {
            childMarkdownRemark {
              html
            }
          }
          textBottom {
            childMarkdownRemark {
              html
            }
          }
        }
        section2Outro {
          childMarkdownRemark {
            html
          }
        }
        fullWidthBanner1 {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        graphTitle {
          childMarkdownRemark {
            html
          }
        }
        graph {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        graphOutro {
          childMarkdownRemark {
            html
          }
        }
        relatedPages {
          title {
            childMarkdownRemark {
              html
            }
          }
          buttons {
            buttonText {
              childMarkdownRemark {
                html
              }
            }
            buttonUrl
          }
        }
        fullWidthBanner2 {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`

export default SafetyPage